import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import Home from "./pages/Home";
import Login from "./pages/Login/Login";
import Dashboard from "./pages/Dashboard";
import NotFound from "./pages/NotFound";
import AuthenticatedRoute from "./Auth/utils/AuthenticatedRoute";
import UnauthenticatedRoute from "./Auth/utils/UnauthenticatedRoute";
import CreateUser from "./pages/Users/CreateUser";
import { AuthProvider } from "./Auth/utils/useAuth";
import { ApiProvider } from "./Auth/api/ApiContext";
import ForgetPass from "./pages/Login/ForgetPass";
import PasswordReset from "./pages/Login/PasswordReset";
import AddEmployee from "./pages/Employee/AddEmployee";
import ViewStatement from "./pages/Employee/ViewStatement";
import EmployeeDetail from "./pages/Employee/EmployeeDetail";
import UsersDetails from "./pages/Users/UsersDetails";
import Attendance from "./pages/Attendance";
import HolidayCalendar from "./pages/HolidayCalendar";
import EmployeeSalary from "./pages/Employee/EmployeeSalary";
import CustomerPage from "./pages/Sales/CustomerPage";
import ProjectsPage from "./pages/Sales/ProjectsPage";
import VendorPage from "./pages/Vendors/VendorPage";
import SupplierPage from "./pages/Sales/SupplierPage";
import ExpensePage from "./pages/Vendors/ExpensePage";

const queryClient = new QueryClient();

const App: React.FC = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <ApiProvider>
        <AuthProvider>
          <Router>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/home" element={<Home />} />
              <Route
                path="/login"
                element={
                  <UnauthenticatedRoute>
                    <Login />
                  </UnauthenticatedRoute>
                }
              />
              <Route
                path="/forget-pass"
                element={
                  <UnauthenticatedRoute>
                    <ForgetPass />
                  </UnauthenticatedRoute>
                }
              />
              <Route
                path="/reset-password"
                element={
                  <UnauthenticatedRoute>
                    <PasswordReset />
                  </UnauthenticatedRoute>
                }
              />
              <Route
                path="/dashboard"
                element={
                  <AuthenticatedRoute>
                    <Dashboard />
                  </AuthenticatedRoute>
                }
              />
              <Route
                path="/user/add"
                element={
                  <AuthenticatedRoute>
                    <CreateUser />
                  </AuthenticatedRoute>
                }
              />
              <Route
                path="/user/view"
                element={
                  <AuthenticatedRoute>
                    <UsersDetails />
                  </AuthenticatedRoute>
                }
              />
              <Route
                path="/attendance"
                element={
                  <AuthenticatedRoute>
                    <Attendance />
                  </AuthenticatedRoute>
                }
              />
              <Route
                path="/holiday/calendar"
                element={
                  <AuthenticatedRoute>
                    <HolidayCalendar />
                  </AuthenticatedRoute>
                }
              />
              <Route
                path="/employee/add"
                element={
                  <AuthenticatedRoute>
                    <AddEmployee />
                  </AuthenticatedRoute>
                }
              />
              <Route
                path="/employee/view"
                element={
                  <AuthenticatedRoute>
                    <EmployeeDetail />
                  </AuthenticatedRoute>
                }
              />
              <Route
                path="/employee/salary"
                element={
                  <AuthenticatedRoute>
                    <EmployeeSalary />
                  </AuthenticatedRoute>
                }
              />
              <Route
                path="/employee/statement"
                element={
                  <AuthenticatedRoute>
                    <ViewStatement />
                  </AuthenticatedRoute>
                }
              />
              <Route
                path="/employee/statement"
                element={
                  <AuthenticatedRoute>
                    <ViewStatement />
                  </AuthenticatedRoute>
                }
              />
              <Route
                path="/sales/customers"
                element={
                  <AuthenticatedRoute>
                    <CustomerPage />
                  </AuthenticatedRoute>
                }
              />
              <Route
                path="/sales/projects"
                element={
                  <AuthenticatedRoute>
                    <ProjectsPage />
                  </AuthenticatedRoute>
                }
              />
              <Route
                path="/sales/suppliers"
                element={
                  <AuthenticatedRoute>
                    <SupplierPage />
                  </AuthenticatedRoute>
                }
              />
              <Route
                path="/purchase/vendors"
                element={
                  <AuthenticatedRoute>
                    <VendorPage />
                  </AuthenticatedRoute>
                }
              />
              <Route
                path="/purchase/expenses"
                element={
                  <AuthenticatedRoute>
                    <ExpensePage />
                  </AuthenticatedRoute>
                }
              />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </Router>
        </AuthProvider>
      </ApiProvider>
    </QueryClientProvider>
  );
};

export default App;
